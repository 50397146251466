@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

.fixdBox{
	position:fixed;
	top:0;
	left: 0;
	overflow: hidden;
	width: 100vw;
	height:100vh;
	background-image: url(/images/index/fv_bg_pc.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.ttlBox{
		width:calc(100% / 2 - 375px / 2);
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.inner{
			margin: 0 auto;
			width: 70%;

			img{
				width: 100%;
				max-width: 350px;
				margin: 0 auto;
			}
		}
	}

	.navBox{
		width:calc(100% / 2 - 375px / 2);
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.inner{
			margin: 0 auto;
			width: 70%;
			max-width: 350px;
		}

		ul{
			border-bottom: 1px solid $borderColor;

			li{
				@include props(mb,8);
				&:last-child{
					@include props(mb,16);
				}

				a{
					@include fz_vw(14);
					letter-spacing: 0.7px;
				}
			}
		}

		.shopInformationBox{
			@include props(pt,24);

			.cvBtn{
				display: inline-block;
				width: 100%;
				@include fz_vw(14);
				letter-spacing: 1px;
				@include props(pt,8);
				@include props(pb,8);
				background-color: $mainColor;
				text-align: center;
				border: 1px solid $mainColor;
				@include props(mb,16);
			}

			p{
				@include fz_vw(14);
				@include props(mb,4);
				text-align: center;
			}

			.snsLinkBox{
				display: flex;
				justify-content: center;
				align-items: center;
				@include props(mt,4);

				a{
					@include props(w,24);
					display: inline-block;
					@include props(ml,8);
					@include props(mr,8);
				}
			}
		}
	}
}

@include mq(pc){
	header{
		background: $basicWhiteColor;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 50%;
		transform: translate(-50% , 0);
		box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
		z-index: 2;
	
		.header{
			.inner{
				@include props(w,1000);
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0 auto;
		
				h1{
					@include fz_vw(48);
				}
		
				nav{
					ul{
						display: flex;
						align-items: center;
		
						li{
							@include props(p,10);
							@include props(m,10);
		
							a{
								@include fz_vw(16);
							}
						}
					}
				}

				.menuBox{
					display: none !important;
				}
			}
		}
	}
}

@include mq(md){
	#header{
		position:fixed;
		top: 0;
		left: 0;
		width: 100%;
		@include props(pl,60,750);
		@include props(pr,60,750);
		@include props(pt,28,750);
		@include props(pb,28,750);
		z-index: 1000;
		background-color: $basicWhiteColor;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
		transition: all .3s ease-in-out;

		&.active{
			box-shadow: inherit;
		}

		.inner{
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;

			h1{
				@include fz_vw(48);
				letter-spacing: 0.5px;
				opacity: 1;
				transition: all .4s ease-in-out;

				&.active{
					opacity: 0;
				}
			}
	
			.menuBox{
				display: flex;

				span{
					@include fz_vw(20);
					letter-spacing: 0.3px;
					display: none;
					transition: all .3s ease-in-out;

					&.active{
						display: block;
					}
				}

				.lineBox{
					@include props(w,60,750);
					position: relative;
					@include props(ml,8,750);

					p{
						&:before{
							content: "";
							position: absolute;
							top: 30%;
							width: 100%;
							height: 1px;
							background-color: $basicColor;
							transition: all .3s ease-in-out;
						}

						&:after{
							content: "";
							position: absolute;
							bottom: 30%;
							width: 100%;
							height: 1px;
							background-color: $basicColor;
							transition: all .3s ease-in-out;
						}

						&.active{
							&:before{
								transform: rotate(45deg);
								top: 48%;
							}
	
							&:after{
								transform: rotate(-45deg);
								bottom: 48%;
							}
						}
					}
				}
			}
		}
	}

	.navContent{
		background-color: $basicWhiteColor;
		height: 100vh;
		width: 100vw;
		position: fixed;
		left: 0;
		top: 0;
		display: none;
		z-index: 100;

		.inner{
			@include props(pr,60,750);
			@include props(pl,60,750);
			@include props(pt,160,750);
			@include props(pb,88,750);
			display: flex;
    		flex-direction: column;
			height: 100%;
			overflow-y: auto;

			.navTtl{
				@include props(mb,120,750);

				h1{
					@include fz_vw(64);
					letter-spacing: 1px;
				}

				span{
					@include fz_vw(20);
					letter-spacing: 1px;
				}
			}

			nav{
				@include props(mb,80,750);
				@include props(pr,60,750);
				@include props(pl,60,750);

				ul{
					li{
						text-align: center;
						@include props(mb,24,750);

						a{
							@include fz_vw(28);
						}
					}
				}
			}

			.information{
				@include props(pr,60,750);
				@include props(pl,60,750);

				.cvBtn{
					display: inline-block;
					width: 100%;
					@include fz_vw(27);
					letter-spacing: 1px;
					@include props(pt,20,750);
					@include props(pb,20,750);
					background-color: $mainColor;
					text-align: center;
					border: 1px solid $mainColor;
					@include props(mb,40,750);
				}

				p{
					@include fz_vw(24);
					letter-spacing: 1px;
					@include props(mb,12,750);
					text-align: center;

					&:last-of-type{
						@include props(mb,24,750);
					}
				}

				.snsLinkBox{
					display: flex;
					justify-content: center;
					align-items: center;
	
					a{
						@include props(w,48,750);
						display: inline-block;
						@include props(ml,12,750);
						@include props(mr,12,750);
					}
				}
			}
		}

		&.active{
			display: block;
		}
	}
}