@charset "utf-8";

/* ==============================================
店舗情報
============================================== */

#storeTop{
	.profile{
		@include props(pt,76.8);

		.heroWrap{
			@include props(mb,112);
			position: relative;
			h2{
				position: absolute;
				text-align: center;
				@include fz_vw(36);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50% , -50%);
			}
		}

		.reserveDesc{
			@include fz_vw(20);
			@include props(mb,60);
			text-align: center;
			@include props(pl,30);
			@include props(pr,30);
			margin-left: auto;
			margin-right: auto;
			@include props(w,1000);
		}

		ul{
			@include props(pl,30);
			@include props(pr,30);
			margin: 0 auto;
			@include props(w,1000);
			li{
				@include props(mb,60);
				@include props(pb,60);
				position: relative;

				&:not(:last-child)::after{
					content: "";
					position: absolute;
					bottom: 0;
					left: 50%;
					width: 80%;
					height: 1px;
					background: #707070;
					transform: translate(-50%,0);
				}

				.descriptionWrap{
					display: flex;
					justify-content: space-between;
					align-items: stretch;
					flex-wrap: wrap;
					h3{
						@include fz_vw(28);
						display: flex;
						align-items: center;
						@include props(mb,30);
						position: relative;
						flex-basis: 100%;
						
						&:before{
							content: "";
							width: .5em;
							height: .5em;
							background-color: $mainColor;
							display: inline-block;
							@include props(mr,10);
						}
					}
	
					dl{
						display: flex;
						justify-content: space-between;
						align-items: baseline;
						flex-wrap: wrap;
						@include props(mb,40);
						flex-basis: 70%;
	
						dt{
							flex-basis: 20%;
							@include fz_vw(18);
							@include props(mb,10);

							&:last-of-type{
								@include props(mb,0);
							}
						}
	
						dd{
							flex-basis: 75%;
							@include fz_vw(18);
							@include props(mb,10);

							&:last-of-type{
								@include props(mb,0);
							}
						}
					}
	
					.linkWrap{
						flex-basis: 30%;
						display: flex;
						flex-direction: column;
						justify-content: baseline;
						@include props(mb,40);

						a{
							@include fz_vw(18);
							@include props(pt,8);
							@include props(pb,8);
							@include props(pr,30);
							@include props(pl,30);
							@include props(mb,10);
							text-align: center;
							background: #45931e;
							color: $basicWhiteColor;
						}	
					}
				}

				iframe{
					width: 100%;
					@include props(h,500);
				}
			}
		}
	}


}

@include mq(md){
	#storeTop{

		.profile{
			@include props(pt,132.78,750);

			.heroWrap{
				@include props(mb,150,750);
				h2{
					@include fz_vw(48);
				}
			}

			.reserveDesc{
				@include fz_vw(28);
				@include props(mb,60,750);
				text-align: left;
				width: 100%;
				max-width: inherit;
				@include props(pl,60,750);
				@include props(pr,60,750);
			}

			ul{
				width: 100%;
				max-width: inherit;
				@include props(pl,60,750);
				@include props(pr,60,750);
				li{
					@include props(mb,60,750);
					@include props(pb,60,750);

					.descriptionWrap{
						h3{
							@include fz_vw(40);
							display: flex;
							align-items: center;
							@include props(mb,30,750);
							
							&:before{
								content: "";
								width: .5em;
								height: .5em;
								background-color: $mainColor;
								display: inline-block;
								@include props(mr,10,750);
							}
						}
	
						dl{
							display: flex;
							justify-content: space-between;
							align-items: baseline;
							flex-wrap: wrap;
							@include props(mb,30,750);
							flex-basis: 100%;
	
							dt{
								flex-basis: 20%;
								@include fz_vw(28);
								@include props(mb,10,750);
							}
	
							dd{
								flex-basis: 75%;
								@include fz_vw(28);
								@include props(mb,10,750);
							}
						}
						.linkWrap{
							flex-basis: 100%;
							display: flex;
							flex-direction: column;
							@include props(mb,40,750);

							a{
								@include fz_vw(28);
								@include props(pt,16,750);
								@include props(pb,16,750);
								@include props(pr,30,750);
								@include props(pl,30,750);
								@include props(mb,25,750);
								text-align: center;

								&:last-of-type{
									@include props(mb,0);
								}
	
								&.reserve{
									background: $mainColor;
								}
								&.line{
									background: #45931e;
									color: $basicWhiteColor;
								}
								&.counseling{
									background: #3c3c3c;
									color: $basicWhiteColor;
								}
							}	
						}
					}

					iframe{
						@include props(h,355,750);
					}
				}
			}
		}
	}
}