@charset "utf-8";

/* ==============================================
脱毛について
============================================== */

#aboutTop{
	.about{
		@include props(pt,76.8);
		.heroWrap{
			@include props(mb,112);
			position: relative;
			h2{
				position: absolute;
				text-align: center;
				@include fz_vw(36);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50% , -50%);
			}
		}

		h3{
			@include fz_vw(24);
			@include props(mb,30);
		}

		.descWrap{
			@include props(pl,30);
			@include props(pr,30);
			@include props(pb,90);
			margin: 0 auto;
			@include props(w,1000);
			p{
				@include fz_vw(18);
				@include props(mb,20);
				@include props(mt,20);
			}

			a{
				display: block;
				width: 40%;
				text-align: center;
				margin: 0 auto;
				@include props(mt,40);
				@include props(mb,60);
				@include props(pb,10);
				@include props(pt,10);
				@include props(pr,10);
				@include props(pl,10);
				@include fz_vw(16);
				background: $mainColor;

				&:first-of-type{
					position: relative;
					
					&:after{
						content: "";
						position: absolute;
						top: 50%;
						@include props(r,20);
						background: url(/images/about/about_icon.png) center center no-repeat;
						background-size: contain;
						width: 1.7em;
						height: 1.7em;
						transform: translate(0,-50%);
					}
				}
			}
		}
	}


}

@include mq(md){
	#aboutTop{

		.about{
			@include props(pt,132.78,750);

			.heroWrap{
				@include props(mb,150,750);
				h2{
					@include fz_vw(48);
				}
			}

			h3{
				@include fz_vw(36);
				@include props(mb,60,750);
			}
	
			.descWrap{
				width: 100%;
				@include props(pl,60,750);
				@include props(pr,60,750);
				@include props(pb,180,750);
				p{
					@include fz_vw(32);
					@include props(mb,40,750);
					@include props(mt,40,750);
				}
	
				a{
					display: block;
					width: 80%;
					text-align: center;
					margin: 0 auto;
					@include props(mb,120,750);
					@include props(pb,20,750);
					@include props(pt,20,750);
					@include props(pr,20,750);
					@include props(pl,20,750);
					@include fz_vw(28);
					background: $mainColor;
	
					&:first-of-type{
						position: relative;
						
						&:after{
							content: "";
							position: absolute;
							top: 50%;
							@include props(r,40,750);
							background: url(/images/about/about_icon.png) center center no-repeat;
							background-size: contain;
							width: 1.7em;
							height: 1.7em;
							transform: translate(0,-50%);
						}
					}
				}
			}
		}
	}
}