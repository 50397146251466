@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

//モーダルコンテント
.detailModalContent{
	width: 100%;
	height: 100%;
	margin: 0;
	min-height: 100%;
	position: fixed;
	z-index: 1000;
	display: none;
	top: 0;
	left: 50%;
	transform: translate(-50%,0);

	.close{
		position: absolute;
		@include props(t,15);
		@include props(r,15);
		z-index: 100;
		opacity: 1;
		
		a{
			display: block;
			@include props(w,30);
			@include props(h,30);
			background: url(/images/index/modal_close_btn.svg);
			background-size: contain;

			&:hover{
				cursor: pointer;
			}
		}
	}

	.modalInner{
		border: 5px solid $mainColor;
		overflow-y: auto;
		position: relative;
		margin: 0 auto;
		text-align: center;
		@include props(pt,88);
		@include props(pb,88);
		height: 100vh;
		background-color: $basicWhiteColor;

		.machineDesc{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			@include props(w,1000);
			@include props(mb,120);

			h4{
				@include fz_vw(24);
				width: 100%;
				@include props(mb,60);
				font-weight: bold;
			}

			.detailContents{
				display: flex;
				flex-direction: column;
				@include props(w,480);

				.subTtl{
					@include fz_vw(18);
					letter-spacing: 0.7px;
					@include props(mb,40);
				}
	
				p{
					@include fz_vw(16);
					letter-spacing: 0.7px;
					@include props(mb,40);
					text-align: left;
				}

				img{
					margin: auto 0 0 0 ;
				}
			}
		}

		.caseDesc{
			width: 100%;
			@include props(pt,60);
			@include props(w,1000);
			margin-left: auto;
			margin-right: auto;
			border-top: 3px solid $mainColor;

			h4{
				@include fz_vw(24);
				width: 100%;
				@include props(mb,60);
				text-align: center;
				font-weight: bold;
			}

			.caseImgWrap{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				@include props(mb,60);
				position: relative;

				&::before{
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					@include props(w,20);
					@include props(h,20);
					transform: translate(-75%,0)rotate(45deg);
					border-top: 3px solid $mainColor;
					border-right: 3px solid $mainColor;
				}

				h5{
					text-align: center;
					@include fz_vw(18);
					@include props(mb,30);
					width: 100%;
				}

				.imgBox{
					@include props(w,480);
				}
			}
		}
	}
}

@include mq(md){
	.detailModalContent{
		width: 100%;
	
		.close{
			@include props(t,30,750);
			@include props(r,30,750);
			
			a{
				@include props(w,55,750);
				@include props(h,55,750);
	
				&:hover{
					cursor: pointer;
				}
			}
		}
	
		.modalInner{
			@include props(pt,175,750);
			@include props(pr,60,750);
			@include props(pl,60,750);
			@include props(pb,175,750);

			.machineDesc{
				flex-direction: column;
				max-width: inherit;
				width: 100%;

				h4{
					@include fz_vw(32);
					@include props(mb,60,750);
				}

				.detailContents{
					width: 100%;
	
					&:first-of-type{
						@include props(pb,104,750);
						@include props(mb,104,750);
						position: relative;

						&:after{
							content: "";
							width: 80%;
							height: 1px;
							background: #707070;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translate(-50%,0);
						}
					}
		
					.subTtl{
						@include fz_vw(32);
						@include props(mb,40,750);
					}
		
					p{
						@include fz_vw(28);
						@include props(mb,40,750);
					}
				}
			}
			.caseDesc{
				width: 100%;
				@include props(pt,60,750);
				h4{
					@include fz_vw(32);
					@include props(mb,60,750);
				}
	
				.caseImgWrap{
					@include props(mb,60,750);
	
					&::before{
						content: "";
						top: 60%;
						@include props(w,20,750);
						@include props(h,20,750);
					}
	
					h5{
						text-align: center;
						@include fz_vw(28);
						@include props(mb,30,750);
					}
	
					.imgBox{
						@include props(w,280,750);
					}
				}
			}
		}
	}
}

