@charset "utf-8";

/* =====================================================
フッター
===================================================== */
#footer{
	background-color: #e5e1dd;
	position: relative;
	@include props(pb,30);
	@include props(pt,60);

	.inner{
		@include props(w,1000);
		@include props(pl,30);
		@include props(pr,30);
		@include props(mb,60);
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		flex-wrap: wrap;

		h2{
			@include fz_vw(32);
			width: calc(100% / 3 - 20px);
			line-height: 1.3;

			&:after{
				content: "PRIVATE EPILATION SALON";
				display: block;
				@include fz_vw(14);
			}
		}

		.storeInfomation{
			width: calc(100% / 3 - 20px);
			h3{
				@include fz_vw(18);
				@include props(pb,5);
				@include props(mb,15);
				border-bottom: 1px solid $basicColor;
			}

			ul{
				li{
					@include props(pb,5);
					@include props(pt,5);

					a{
						display: block;
						@include fz_vw(14);
					}
				}
			}
		}

		.infomation{
			width: calc(100% / 3 - 20px);
			h3{
				@include fz_vw(18);
				@include props(pb,5);
				@include props(mb,15);
				border-bottom: 1px solid $basicColor;
			}

			ul{
				li{
					@include props(pb,5);
					@include props(pt,5);

					a{
						display: block;
						@include fz_vw(14);
					}
				}
			}
		}
	}

	small{
		display: block;
		text-align: center;
		@include fz_vw(14);
	}

	@include mq(md){
		@include props(pb,30,750);
		@include props(pt,60,750);
	
		.inner{
			@include props(pl,60,750);
			@include props(pr,60,750);
			@include props(mb,60,750);
			width: 100%;
			max-width: inherit;

			h2{
				@include fz_vw(48);
				width: 100%;
	
				&:after{
					content: "PRIVATE EPILATION SALON";
					display: block;
					@include fz_vw(24);
				}
			}
	
			.storeInfomation{
				width: 100%;
				@include props(mt,60,750);
				@include props(mb,60,750);
				h3{
					@include fz_vw(32);
					@include props(pb,10,750);
					@include props(mb,30,750);
				}
	
				ul{
					li{
						@include props(pb,10,750);
						@include props(pt,10,750);
	
						a{
							@include fz_vw(28);
						}
					}
				}
			}
	
			.infomation{
				width: 100%;
				h3{
					@include fz_vw(32);
					@include props(pb,10,750);
					@include props(mb,30,750);
				}
	
				ul{
					li{
						@include props(pb,10,750);
						@include props(pt,10,750);
	
						a{
							@include fz_vw(28);
						}
					}
				}
			}
		}
	
		small{
			@include fz_vw(24);
		}
	}
}