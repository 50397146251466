@charset "utf-8";

/* ==============================================
店舗情報
============================================== */

#jobTop{
	.job{
		@include props(pt,76.8);

		.heroWrap{
			@include props(mb,112);
			position: relative;
			h2{
				position: absolute;
				text-align: center;
				@include fz_vw(36);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50% , -50%);
			}
		}

		.descWrap{
			@include props(pl,30);
			@include props(pr,30);
			@include props(pb,90);
			margin: 0 auto;
			@include props(w,1000);

			.descWrapInner{
				@include props(mb,60);

				&:first-of-type{
					img{
						@include props(mt,30);
						box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
					}
				}

				&.flex{
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					justify-content: space-between;

					p{
						width: 55%;
					}

					img{
						width: 40%;
						box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
					}
				}
				h3{
					@include fz_vw(24);
					font-weight: bold;
					text-align: center;
					position: relative;
					@include props(mb,30);
					@include props(pb,10);
					width: 100%;

					&:after{
						content: "";
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%,0);
						height: 3px;
						@include props(w,60);
						background: $mainColor;
					}
				}

				p{
					text-align: inherit;
					@include fz_vw(18);
				}

				a{
					display: block;
					text-align: center;
					width: 40%;
					margin: 0 auto;
					@include fz_vw(18);
					@include props(pt,10);
					@include props(pb,10);
					@include props(mb,30);
					@include props(mt,30);
					background: $mainColor;
				}
			}

			.descWelfareBenefits{
				position: relative;
				@include props(mb,60);
				@include props(pb,30);
				@include props(pt,30);

				&:before{
					content: "";
					position: absolute;
					width: 100vw;
					height: 100%;
					background: #fff;
					top: 0;
					left: 50%;
					transform: translate(-50%,0);
					z-index: -1;
				}

				h3{
					@include fz_vw(24);
					@include props(mb,15);
					@include props(pb,10);
					font-weight: bold;
					text-align: center;
					position: relative;

					&:after{
						content: "";
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%,0);
						height: 3px;
						@include props(w,60);
						background: $mainColor;
					}
				}

				ul{
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					flex-wrap: wrap;

					li{
						width: calc(100% / 4 - 10px);

						.textBox{
							@include props(pb,10);
							@include props(pt,10);

							h4{
								@include fz_vw(18);
								font-weight: bold;
								text-align: center;
								@include props(mb,10);
							}

							p{
								@include fz_vw(16);
							}
						}
					}
				}
			}
		}
	}
}

@include mq(md){
	#jobTop{

		.job{
			@include props(pt,132.78,750);

			.heroWrap{
				@include props(mb,150,750);
				h2{
					@include fz_vw(48);
				}
			}

			.descWrap{
				width: 100%;
				@include props(pl,60,750);
				@include props(pr,60,750);
				@include props(pb,180,750);

				.descWrapInner{
					@include props(mb,120,750);
	
					&:first-of-type{
						img{
							@include props(mt,60,750)
						}
					}
	
					&.flex{
	
						p{
							width: 100%;
						}
	
						img{
							width: 100%;
							@include props(mt,60,750)
						}
					}
					h3{
						@include fz_vw(28);
						@include props(mb,60,750);
						@include props(pb,15,750);

						&:after{
							@include props(w,120,750);
						}
					}
	
					p{
						@include fz_vw(28);
					}
	
					a{
						@include fz_vw(28);
						@include props(pt,20,750);
						@include props(pb,20,750);
						@include props(mb,60,750);
						@include props(mt,60,750);
						width: 80%;
					}
				}
	
				.descWelfareBenefits{
					@include props(mb,120,750);
					@include props(pb,60,750);
					@include props(pt,60,750);
	
					h3{
						@include fz_vw(28);
						@include props(mb,30,750);
						@include props(pb,15,750);

						&:after{
							@include props(w,120,750);
						}
					}
	
					ul{
	
						li{
							width: 100%;
							@include props(mb,30,750);

							img{
								width: 50%;
								margin: 0 auto;
							}
	
							.textBox{
								@include props(pb,20,750);
								@include props(pt,20,750);
	
								h4{
									@include fz_vw(30);
									@include props(mb,20,750);
								}
	
								p{
									@include fz_vw(28);
								}
							}
						}
					}
				}
			}
		}
	}
}