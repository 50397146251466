@charset "utf-8";

/* アニメーション関連---------------------- */
.fadeIn {
	$fadeIn: &;
	opacity: 0;
	transform: none;
	transition: opacity .8s .6s, transform .5s .5s;
	&.active {
	  opacity: 1;
	  transform: none;
	  #{$fadeIn}_second {
		opacity: 1;
		transform: none;
	  }
	  #{$fadeIn}_third {
		opacity: 1;
		transform: none;
	  }
	}
	#{$fadeIn}_second {
	  opacity: 0;
	  transform: translateY(30px);
	  transition: opacity .5s 1s, transform .5s 1s;
	}
	#{$fadeIn}_third {
	  opacity: 0;
	  transform: translateY(30px);
	  transition: opacity .5s 1.5s, transform .5s 1.5s;
	}
  }