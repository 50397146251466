@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

.pcHidden{
  display: none;

  @include mq(md){
    display: block;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: $basicColor;
  background-color: $basicWhiteColor;
  
  &.fixedBody {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;

    #header {
      &.scrollDown{
        .headerContents{
          .navBtnBox{
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }
  }
}

/* ハイトカラー消去
------------------------------------------------------------- */


/* 共通デザイン
------------------------------------------------------------- */

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */