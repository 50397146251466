@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #373737;
  font-size: 62.5%;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #909090;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #909090;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

.pcHidden {
  display: none;
}

@media screen and (max-width: 767px) {
  .pcHidden {
    display: block;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Serif JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #373737;
  background-color: #f3f3f3;
}

body.fixedBody {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
}

body.fixedBody #header.scrollDown .headerContents .navBtnBox {
  background-color: rgba(0, 0, 0, 0);
}

/* ハイトカラー消去
------------------------------------------------------------- */
/* 共通デザイン
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* =====================================
ヘッダー
===================================== */
.fixdBox {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-image: url(/images/index/fv_bg_pc.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.fixdBox .ttlBox {
  width: calc(100% / 2 - 375px / 2);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fixdBox .ttlBox .inner {
  margin: 0 auto;
  width: 70%;
}

.fixdBox .ttlBox .inner img {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.fixdBox .navBox {
  width: calc(100% / 2 - 375px / 2);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fixdBox .navBox .inner {
  margin: 0 auto;
  width: 70%;
  max-width: 350px;
}

.fixdBox .navBox ul {
  border-bottom: 1px solid #909090;
}

.fixdBox .navBox ul li {
  margin-bottom: 0.58565vw;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox ul li {
    margin-bottom: 8px;
  }
}

.fixdBox .navBox ul li:last-child {
  margin-bottom: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox ul li:last-child {
    margin-bottom: 16px;
  }
}

.fixdBox .navBox ul li a {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox ul li a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .fixdBox .navBox ul li a {
    font-size: 1.86667vw;
  }
}

.fixdBox .navBox .shopInformationBox {
  padding-top: 1.75695vw;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox {
    padding-top: 24px;
  }
}

.fixdBox .navBox .shopInformationBox .cvBtn {
  display: inline-block;
  width: 100%;
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 1px;
  padding-top: 0.58565vw;
  padding-bottom: 0.58565vw;
  background-color: #FFD610;
  text-align: center;
  border: 1px solid #FFD610;
  margin-bottom: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox .cvBtn {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .fixdBox .navBox .shopInformationBox .cvBtn {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox .cvBtn {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox .cvBtn {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox .cvBtn {
    margin-bottom: 16px;
  }
}

.fixdBox .navBox .shopInformationBox p {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  margin-bottom: 0.29283vw;
  text-align: center;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  .fixdBox .navBox .shopInformationBox p {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox p {
    margin-bottom: 4px;
  }
}

.fixdBox .navBox .shopInformationBox .snsLinkBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.29283vw;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox .snsLinkBox {
    margin-top: 4px;
  }
}

.fixdBox .navBox .shopInformationBox .snsLinkBox a {
  width: 1.75695vw;
  max-width: 24px;
  display: inline-block;
  margin-left: 0.58565vw;
  margin-right: 0.58565vw;
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox .snsLinkBox a {
    margin-left: 8px;
  }
}

@media screen and (min-width: 1366px) {
  .fixdBox .navBox .shopInformationBox .snsLinkBox a {
    margin-right: 8px;
  }
}

@media screen and (min-width: 768px) {
  header {
    background: #f3f3f3;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
  header .header .inner {
    width: 73.20644vw;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  header .header .inner h1 {
    font-size: 4.8rem;
    font-size: 3.51391vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1366px) {
  header .header .inner h1 {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  header .header .inner h1 {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 768px) {
  header .header .inner nav ul {
    display: flex;
    align-items: center;
  }
  header .header .inner nav ul li {
    padding: 0.73206vw;
    margin: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1366px) {
  header .header .inner nav ul li {
    padding: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1366px) {
  header .header .inner nav ul li {
    margin: 10px;
  }
}

@media screen and (min-width: 768px) {
  header .header .inner nav ul li a {
    font-size: 1.6rem;
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1366px) {
  header .header .inner nav ul li a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  header .header .inner nav ul li a {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 768px) {
  header .header .inner .menuBox {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  #header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-top: 3.73333vw;
    padding-bottom: 3.73333vw;
    z-index: 1000;
    background-color: #f3f3f3;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    transition: all .3s ease-in-out;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header {
    padding-top: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header {
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 767px) {
  #header.active {
    box-shadow: inherit;
  }
  #header .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  #header .inner h1 {
    font-size: 4.8rem;
    font-size: 3.51391vw;
    letter-spacing: 0.5px;
    opacity: 1;
    transition: all .4s ease-in-out;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner h1 {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .inner h1 {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) {
  #header .inner h1.active {
    opacity: 0;
  }
  #header .inner .menuBox {
    display: flex;
  }
  #header .inner .menuBox span {
    font-size: 2rem;
    font-size: 1.46413vw;
    letter-spacing: 0.3px;
    display: none;
    transition: all .3s ease-in-out;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .menuBox span {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .inner .menuBox span {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .menuBox span.active {
    display: block;
  }
  #header .inner .menuBox .lineBox {
    width: 8vw;
    max-width: 60px;
    position: relative;
    margin-left: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #header .inner .menuBox .lineBox {
    margin-left: 8px;
  }
}

@media screen and (max-width: 767px) {
  #header .inner .menuBox .lineBox p:before {
    content: "";
    position: absolute;
    top: 30%;
    width: 100%;
    height: 1px;
    background-color: #373737;
    transition: all .3s ease-in-out;
  }
  #header .inner .menuBox .lineBox p:after {
    content: "";
    position: absolute;
    bottom: 30%;
    width: 100%;
    height: 1px;
    background-color: #373737;
    transition: all .3s ease-in-out;
  }
  #header .inner .menuBox .lineBox p.active:before {
    transform: rotate(45deg);
    top: 48%;
  }
  #header .inner .menuBox .lineBox p.active:after {
    transform: rotate(-45deg);
    bottom: 48%;
  }
  .navContent {
    background-color: #f3f3f3;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    z-index: 100;
  }
  .navContent .inner {
    padding-right: 8vw;
    padding-left: 8vw;
    padding-top: 21.33333vw;
    padding-bottom: 11.73333vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner {
    padding-bottom: 88px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .navTtl {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .navTtl {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .navTtl h1 {
    font-size: 6.4rem;
    font-size: 4.68521vw;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .navTtl h1 {
    font-size: 6.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .navContent .inner .navTtl h1 {
    font-size: 8.53333vw;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .navTtl span {
    font-size: 2rem;
    font-size: 1.46413vw;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .navTtl span {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .navContent .inner .navTtl span {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner nav {
    margin-bottom: 10.66667vw;
    padding-right: 8vw;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner nav {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner nav {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner nav {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner nav ul li {
    text-align: center;
    margin-bottom: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner nav ul li {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner nav ul li a {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner nav ul li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .navContent .inner nav ul li a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .information {
    padding-right: 8vw;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .information .cvBtn {
    display: inline-block;
    width: 100%;
    font-size: 2.7rem;
    font-size: 1.97657vw;
    letter-spacing: 1px;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
    background-color: #FFD610;
    text-align: center;
    border: 1px solid #FFD610;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information .cvBtn {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .navContent .inner .information .cvBtn {
    font-size: 3.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information .cvBtn {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information .cvBtn {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information .cvBtn {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .information p {
    font-size: 2.4rem;
    font-size: 1.75695vw;
    letter-spacing: 1px;
    margin-bottom: 1.6vw;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .navContent .inner .information p {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information p {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .information p:last-of-type {
    margin-bottom: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information p:last-of-type {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .navContent .inner .information .snsLinkBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navContent .inner .information .snsLinkBox a {
    width: 6.4vw;
    max-width: 48px;
    display: inline-block;
    margin-left: 1.6vw;
    margin-right: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information .snsLinkBox a {
    margin-left: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .navContent .inner .information .snsLinkBox a {
    margin-right: 12px;
  }
}

@media screen and (max-width: 767px) {
  .navContent.active {
    display: block;
  }
}

/* =====================================================
フッター
===================================================== */
#footer {
  background-color: #e5e1dd;
  position: relative;
  padding-bottom: 2.19619vw;
  padding-top: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #footer {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #footer {
    padding-top: 60px;
  }
}

#footer .inner {
  width: 73.20644vw;
  max-width: 1000px;
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  margin-bottom: 4.39239vw;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

@media screen and (min-width: 1366px) {
  #footer .inner {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner {
    margin-bottom: 60px;
  }
}

#footer .inner h2 {
  font-size: 3.2rem;
  font-size: 2.34261vw;
  width: calc(100% / 3 - 20px);
  line-height: 1.3;
}

@media screen and (min-width: 1366px) {
  #footer .inner h2 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner h2 {
    font-size: 4.26667vw;
  }
}

#footer .inner h2:after {
  content: "PRIVATE EPILATION SALON";
  display: block;
  font-size: 1.4rem;
  font-size: 1.02489vw;
}

@media screen and (min-width: 1366px) {
  #footer .inner h2:after {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner h2:after {
    font-size: 1.86667vw;
  }
}

#footer .inner .storeInfomation {
  width: calc(100% / 3 - 20px);
}

#footer .inner .storeInfomation h3 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  padding-bottom: 0.36603vw;
  margin-bottom: 1.0981vw;
  border-bottom: 1px solid #373737;
}

@media screen and (min-width: 1366px) {
  #footer .inner .storeInfomation h3 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .storeInfomation h3 {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner .storeInfomation h3 {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner .storeInfomation h3 {
    margin-bottom: 15px;
  }
}

#footer .inner .storeInfomation ul li {
  padding-bottom: 0.36603vw;
  padding-top: 0.36603vw;
}

@media screen and (min-width: 1366px) {
  #footer .inner .storeInfomation ul li {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner .storeInfomation ul li {
    padding-top: 5px;
  }
}

#footer .inner .storeInfomation ul li a {
  display: block;
  font-size: 1.4rem;
  font-size: 1.02489vw;
}

@media screen and (min-width: 1366px) {
  #footer .inner .storeInfomation ul li a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .storeInfomation ul li a {
    font-size: 1.86667vw;
  }
}

#footer .inner .infomation {
  width: calc(100% / 3 - 20px);
}

#footer .inner .infomation h3 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  padding-bottom: 0.36603vw;
  margin-bottom: 1.0981vw;
  border-bottom: 1px solid #373737;
}

@media screen and (min-width: 1366px) {
  #footer .inner .infomation h3 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .infomation h3 {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner .infomation h3 {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner .infomation h3 {
    margin-bottom: 15px;
  }
}

#footer .inner .infomation ul li {
  padding-bottom: 0.36603vw;
  padding-top: 0.36603vw;
}

@media screen and (min-width: 1366px) {
  #footer .inner .infomation ul li {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .inner .infomation ul li {
    padding-top: 5px;
  }
}

#footer .inner .infomation ul li a {
  display: block;
  font-size: 1.4rem;
  font-size: 1.02489vw;
}

@media screen and (min-width: 1366px) {
  #footer .inner .infomation ul li a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .infomation ul li a {
    font-size: 1.86667vw;
  }
}

#footer small {
  display: block;
  text-align: center;
  font-size: 1.4rem;
  font-size: 1.02489vw;
}

@media screen and (min-width: 1366px) {
  #footer small {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #footer small {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #footer {
    padding-bottom: 4vw;
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner {
    padding-left: 8vw;
    padding-right: 8vw;
    margin-bottom: 8vw;
    width: 100%;
    max-width: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner h2 {
    font-size: 4.8rem;
    font-size: 3.51391vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner h2 {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .inner h2 {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner h2:after {
    content: "PRIVATE EPILATION SALON";
    display: block;
    font-size: 2.4rem;
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner h2:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .inner h2:after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .storeInfomation {
    width: 100%;
    margin-top: 8vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .storeInfomation h3 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    padding-bottom: 1.33333vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation h3 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .inner .storeInfomation h3 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation h3 {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation h3 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .storeInfomation ul li {
    padding-bottom: 1.33333vw;
    padding-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation ul li {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation ul li {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .storeInfomation ul li a {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .storeInfomation ul li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .inner .storeInfomation ul li a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .infomation {
    width: 100%;
  }
  #footer .inner .infomation h3 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    padding-bottom: 1.33333vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .infomation h3 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .inner .infomation h3 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .infomation h3 {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .infomation h3 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .infomation ul li {
    padding-bottom: 1.33333vw;
    padding-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .infomation ul li {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .infomation ul li {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  #footer .inner .infomation ul li a {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer .inner .infomation ul li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .inner .infomation ul li a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer small {
    font-size: 2.4rem;
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #footer small {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer small {
    font-size: 3.2vw;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0;
  transform: none;
  transition: opacity .8s .6s, transform .5s .5s;
}

.fadeIn.active {
  opacity: 1;
  transform: none;
}

.fadeIn.active .fadeIn_second {
  opacity: 1;
  transform: none;
}

.fadeIn.active .fadeIn_third {
  opacity: 1;
  transform: none;
}

.fadeIn .fadeIn_second {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity .5s 1s, transform .5s 1s;
}

.fadeIn .fadeIn_third {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity .5s 1.5s, transform .5s 1.5s;
}

/* ボタン装飾------------------------------ */
.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

.detailModalContent {
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  position: fixed;
  z-index: 1000;
  display: none;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.detailModalContent .close {
  position: absolute;
  top: 1.0981vw;
  right: 1.0981vw;
  z-index: 100;
  opacity: 1;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .close {
    top: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .detailModalContent .close {
    right: 15px;
  }
}

.detailModalContent .close a {
  display: block;
  width: 2.19619vw;
  max-width: 30px;
  height: 2.19619vw;
  max-height: 30px;
  background: url(/images/index/modal_close_btn.svg);
  background-size: contain;
}

.detailModalContent .close a:hover {
  cursor: pointer;
}

.detailModalContent .modalInner {
  border: 5px solid #FFD610;
  overflow-y: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding-top: 6.44217vw;
  padding-bottom: 6.44217vw;
  height: 100vh;
  background-color: #f3f3f3;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner {
    padding-top: 88px;
  }
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner {
    padding-bottom: 88px;
  }
}

.detailModalContent .modalInner .machineDesc {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 73.20644vw;
  max-width: 1000px;
  margin-bottom: 8.78477vw;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc {
    margin-bottom: 120px;
  }
}

.detailModalContent .modalInner .machineDesc h4 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  width: 100%;
  margin-bottom: 4.39239vw;
  font-weight: bold;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc h4 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc h4 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc h4 {
    margin-bottom: 60px;
  }
}

.detailModalContent .modalInner .machineDesc .detailContents {
  display: flex;
  flex-direction: column;
  width: 35.13909vw;
  max-width: 480px;
}

.detailModalContent .modalInner .machineDesc .detailContents .subTtl {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  letter-spacing: 0.7px;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents .subTtl {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc .detailContents .subTtl {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents .subTtl {
    margin-bottom: 40px;
  }
}

.detailModalContent .modalInner .machineDesc .detailContents p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  letter-spacing: 0.7px;
  margin-bottom: 2.92826vw;
  text-align: left;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc .detailContents p {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents p {
    margin-bottom: 40px;
  }
}

.detailModalContent .modalInner .machineDesc .detailContents img {
  margin: auto 0 0 0;
}

.detailModalContent .modalInner .caseDesc {
  width: 100%;
  padding-top: 4.39239vw;
  width: 73.20644vw;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  border-top: 3px solid #FFD610;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc {
    padding-top: 60px;
  }
}

.detailModalContent .modalInner .caseDesc h4 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  width: 100%;
  margin-bottom: 4.39239vw;
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc h4 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc h4 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc h4 {
    margin-bottom: 60px;
  }
}

.detailModalContent .modalInner .caseDesc .caseImgWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 4.39239vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap {
    margin-bottom: 60px;
  }
}

.detailModalContent .modalInner .caseDesc .caseImgWrap::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.46413vw;
  max-width: 20px;
  height: 1.46413vw;
  max-height: 20px;
  transform: translate(-75%, 0) rotate(45deg);
  border-top: 3px solid #FFD610;
  border-right: 3px solid #FFD610;
}

.detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
  text-align: center;
  font-size: 1.8rem;
  font-size: 1.31772vw;
  margin-bottom: 2.19619vw;
  width: 100%;
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
    margin-bottom: 30px;
  }
}

.detailModalContent .modalInner .caseDesc .caseImgWrap .imgBox {
  width: 35.13909vw;
  max-width: 480px;
}

@media screen and (max-width: 767px) {
  .detailModalContent {
    width: 100%;
  }
  .detailModalContent .close {
    top: 4vw;
    right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .close {
    top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .close {
    right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .close a {
    width: 7.33333vw;
    max-width: 55px;
    height: 7.33333vw;
    max-height: 55px;
  }
  .detailModalContent .close a:hover {
    cursor: pointer;
  }
  .detailModalContent .modalInner {
    padding-top: 23.33333vw;
    padding-right: 8vw;
    padding-left: 8vw;
    padding-bottom: 23.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner {
    padding-top: 175px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner {
    padding-bottom: 175px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc {
    flex-direction: column;
    max-width: inherit;
    width: 100%;
  }
  .detailModalContent .modalInner .machineDesc h4 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc h4 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc h4 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc h4 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc .detailContents {
    width: 100%;
  }
  .detailModalContent .modalInner .machineDesc .detailContents:first-of-type {
    padding-bottom: 13.86667vw;
    margin-bottom: 13.86667vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents:first-of-type {
    padding-bottom: 104px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents:first-of-type {
    margin-bottom: 104px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc .detailContents:first-of-type:after {
    content: "";
    width: 80%;
    height: 1px;
    background: #707070;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .detailModalContent .modalInner .machineDesc .detailContents .subTtl {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents .subTtl {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc .detailContents .subTtl {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents .subTtl {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc .detailContents p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .detailModalContent .modalInner .machineDesc .detailContents p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .machineDesc .detailContents p {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc {
    width: 100%;
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc h4 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc h4 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc h4 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc h4 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap::before {
    content: "";
    top: 60%;
    width: 2.66667vw;
    max-width: 20px;
    height: 2.66667vw;
    max-height: 20px;
  }
  .detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
    text-align: center;
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap h5 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .detailModalContent .modalInner .caseDesc .caseImgWrap .imgBox {
    width: 37.33333vw;
    max-width: 280px;
  }
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop {
  padding-top: 5.62225vw;
}

@media screen and (min-width: 1366px) {
  #indexTop {
    padding-top: 76.8px;
  }
}

#indexTop .fv {
  width: 100vw;
}

#indexTop .fv .pcOnly {
  width: 100%;
}

#indexTop .concept {
  padding-top: 8.19912vw;
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  margin: 0 auto;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept {
    padding-top: 112px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept {
    padding-right: 30px;
  }
}

#indexTop .concept .conceptRead {
  margin: 0 auto;
  margin-bottom: 4.09956vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptRead {
    margin-bottom: 56px;
  }
}

#indexTop .concept .conceptRead h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  letter-spacing: 0.7px;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptRead h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptRead h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptRead h3 {
    margin-bottom: 40px;
  }
}

#indexTop .concept .conceptRead p {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptRead p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptRead p {
    font-size: 2.4vw;
  }
}

#indexTop .concept .conceptDescription ul li {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 4.68521vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li {
    margin-bottom: 64px;
  }
}

#indexTop .concept .conceptDescription ul li .imgBox {
  width: 34.99268vw;
  max-width: 478px;
}

#indexTop .concept .conceptDescription ul li .imgBox img {
  width: 100%;
}

#indexTop .concept .conceptDescription ul li .txtBox {
  width: 29.28258vw;
  max-width: 400px;
}

#indexTop .concept .conceptDescription ul li .txtBox h4 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  letter-spacing: 0.7px;
  margin-bottom: 1.75695vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox h4 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox h4 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox h4 {
    margin-bottom: 24px;
  }
}

#indexTop .concept .conceptDescription ul li .txtBox p {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  letter-spacing: 0.7px;
  padding-bottom: 1.1713vw;
  border-bottom: 1px solid #909090;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    padding-bottom: 16px;
  }
}

#indexTop .concept .conceptDescription ul li .txtBox a {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  position: relative;
  color: #909090;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox a {
    font-size: 1.86667vw;
  }
}

#indexTop .concept .conceptDescription ul li .txtBox a.modal1:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -14px;
  background-image: url(/images/index/modal_btn.svg);
  width: 12px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(0, -48%);
}

#indexTop .concept .conceptDescription ul::after {
  content: "";
  display: block;
  background-image: url(/images/index/consept_bt1_pc@2x.jpg);
  height: 34.55344vw;
  max-height: 472px;
  width: 54.90483vw;
  max-width: 750px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 2.19619vw;
  margin-right: -2.19619vw;
  margin-left: auto;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul::after {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul::after {
    margin-right: -30px;
  }
}

#indexTop .concept .conceptImgBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.19619vw;
  margin-right: -2.19619vw;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptImgBox {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptImgBox {
    margin-right: -30px;
  }
}

#indexTop .concept .conceptImgBox .rowItem {
  margin-left: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptImgBox .rowItem {
    margin-left: 30px;
  }
}

#indexTop .menu {
  padding-top: 9.66325vw;
  padding-bottom: 4.68521vw;
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  position: relative;
  margin: -132px auto 0;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu {
    padding-top: 132px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu {
    padding-bottom: 64px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu {
    padding-right: 30px;
  }
}

#indexTop .menu:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  background: #E5E1DD;
  transform: translate(-50%, 0);
  z-index: -1;
}

#indexTop .menu:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url(/images/index/menu_bt1_pc@2x.jpg);
  width: 100%;
  height: 28.69693vw;
  max-height: 392px;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(0, 90%);
  display: block;
}

#indexTop .menu h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  letter-spacing: 0.7px;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu h3 {
    margin-bottom: 40px;
  }
}

#indexTop .menu p {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  letter-spacing: 0.7px;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu p {
    font-size: 2.4vw;
  }
}

#indexTop .menu .menuBox {
  margin-top: 20px;
}

#indexTop .menu .menuBox ul {
  display: flex;
}

#indexTop .menu .menuBox ul li {
  flex-basis: 50%;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  letter-spacing: 0.7px;
  text-align: center;
  padding-top: 0.73206vw;
  padding-bottom: 0.73206vw;
  margin-bottom: 1.46413vw;
  color: #909090;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox ul li {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    margin-bottom: 20px;
  }
}

#indexTop .menu .menuBox ul li:hover {
  cursor: pointer;
}

#indexTop .menu .menuBox ul li.active {
  border-bottom: 2px solid #FFD610;
  color: #373737;
}

#indexTop .menu .menuBox span {
  font-size: 1.4rem;
  font-size: 1.02489vw;
  letter-spacing: 0.7px;
  line-height: 1.5;
  display: block;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox span {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox span {
    margin-bottom: 20px;
  }
}

#indexTop .menu .menuBox .menuList {
  background-image: url(/images/index/menu_bg_pc.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 2.92826vw;
  padding-bottom: 1.75695vw;
  padding-right: 2.19619vw;
  padding-left: 2.19619vw;
  display: none;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-bottom: 24px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-left: 30px;
  }
}

#indexTop .menu .menuBox .menuList.active {
  display: block;
}

#indexTop .menu .menuBox .menuList h4 {
  font-size: 2rem;
  font-size: 1.46413vw;
  letter-spacing: 0.7px;
  border-bottom: 2px solid #FFF3BA;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList h4 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList h4 {
    font-size: 2.66667vw;
  }
}

#indexTop .menu .menuBox .menuList h4:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #FFD610;
}

#indexTop .menu .menuBox .menuList dl {
  padding-top: 1.46413vw;
  padding-bottom: 1.46413vw;
  padding-right: 2.19619vw;
  padding-left: 2.19619vw;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-left: 30px;
  }
}

#indexTop .menu .menuBox .menuList dl dt {
  flex-basis: 60%;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList dl dt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dt {
    margin-bottom: 10px;
  }
}

#indexTop .menu .menuBox .menuList dl dt:last-of-type {
  margin-bottom: 0;
}

#indexTop .menu .menuBox .menuList dl dd {
  flex-basis: 40%;
  text-align: end;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dd {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList dl dd {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dd {
    margin-bottom: 10px;
  }
}

#indexTop .menu .menuBox .menuList dl dd:last-of-type {
  margin-bottom: 0;
}

#indexTop .menu .menuBox .menuList span {
  padding-left: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList span {
    padding-left: 30px;
  }
}

#indexTop .menu .menuBox .menuList span:first-of-type {
  margin-bottom: 0.36603vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList span:first-of-type {
    margin-bottom: 5px;
  }
}

#indexTop .instagram {
  margin-top: 5.85652vw;
  margin-bottom: 5.85652vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 28.84334vw;
  padding-bottom: 0vw;
  padding-right: 2.19619vw;
  padding-left: 2.19619vw;
  position: relative;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram {
    padding-top: 394px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram {
    padding-left: 30px;
  }
}

#indexTop .instagram:before {
  content: "";
  display: block;
  background-image: url(/images/index/menu_bt2_pc@2x.jpg);
  position: absolute;
  top: 6.58858vw;
  left: 19.03367vw;
  height: 19.76574vw;
  max-height: 270px;
  width: 36.96925vw;
  max-width: 505px;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram:before {
    top: 90px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram:before {
    left: 260px;
  }
}

#indexTop .instagram h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  letter-spacing: 0.7px;
  margin-bottom: 40px;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram h3 {
    font-size: 3.2vw;
  }
}

#indexTop .instagram p {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  letter-spacing: 0.7px;
  color: #FFD610;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram p {
    font-size: 2.4vw;
  }
}

#indexTop .instagram #instagramContent {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#indexTop .instagram #instagramContent .instagramContentList {
  width: calc(100% / 3);
  padding-left: 0.73206vw;
  padding-right: 0.73206vw;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram #instagramContent .instagramContentList {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram #instagramContent .instagramContentList {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .instagram #instagramContent .instagramContentList {
    margin-bottom: 20px;
  }
}

#indexTop .instagram #instagramContent .instagramContentList img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#indexTop .faq {
  padding-right: 2.19619vw;
  padding-left: 2.19619vw;
  margin: 0 auto 80px;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #indexTop .faq {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .faq {
    padding-left: 30px;
  }
}

#indexTop .faq h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  letter-spacing: 0.7px;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .faq h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .faq h3 {
    margin-bottom: 40px;
  }
}

#indexTop .faq li {
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .faq li {
    margin-bottom: 10px;
  }
}

#indexTop .faq li:last-child {
  margin-bottom: 0;
}

#indexTop .faq li dl {
  border-bottom: 1px solid #373737;
}

#indexTop .faq li dl dt {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  letter-spacing: 0.5px;
  padding: 6px 30px 6px calc(1em + 6px);
  text-indent: -1em;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .faq li dl dt {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq li dl dt {
    font-size: 2.4vw;
  }
}

#indexTop .faq li dl dt:hover {
  cursor: pointer;
}

#indexTop .faq li dl dt:after {
  content: "";
  display: block;
  background-image: url(/images/index/accordion_plus.svg);
  width: 1.1713vw;
  max-width: 16px;
  height: 1.1713vw;
  max-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 0.58565vw;
  transform: translate(0, -50%);
  transition: all .3s ease-in-out;
}

@media screen and (min-width: 1366px) {
  #indexTop .faq li dl dt:after {
    right: 8px;
  }
}

#indexTop .faq li dl dt.active:after {
  background-image: url(/images/index/accordion_minus.svg);
  transition: all .3s ease-in-out;
}

#indexTop .faq li dl dd {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  letter-spacing: 0.5px;
  padding: 6px 15px 6px 30px;
  text-indent: -15px;
  background-color: #E5E1DD;
  display: none;
}

@media screen and (min-width: 1366px) {
  #indexTop .faq li dl dd {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq li dl dd {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop {
    width: 100%;
    padding-top: 17.704vw;
    box-shadow: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop {
    padding-top: 132.78px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .fv {
    display: flex;
    justify-content: space-between;
    padding-left: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .fv {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .fv .txtContent {
    writing-mode: vertical-rl;
    display: flex;
    padding-top: 5.33333vw;
    padding-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .fv .txtContent {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .fv .txtContent {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .fv .txtContent h2 {
    font-size: 2.4rem;
    font-size: 1.75695vw;
    margin-bottom: 23.2vw;
    letter-spacing: 1px;
    line-height: 1.5;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .fv .txtContent h2 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .fv .txtContent h2 {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .fv .txtContent h2 {
    margin-bottom: 174px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .fv .txtContent span {
    font-size: 2.4rem;
    font-size: 1.75695vw;
    line-height: 1.5;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .fv .txtContent span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .fv .txtContent span {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .fv .txtContent span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 45.73333vw;
    max-height: 343px;
    width: 1px;
    background: #707070;
  }
  #indexTop .concept {
    padding-top: 20vw;
    padding-left: 8vw;
    padding-right: 8vw;
    width: inherit !important;
    max-width: inherit !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptRead {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptRead {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptRead h3 {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    margin-bottom: 9.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptRead h3 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .concept .conceptRead h3 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptRead h3 {
    margin-bottom: 72px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptRead p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptRead p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .concept .conceptRead p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .imgBox {
    width: 33.73333vw;
    max-width: 253px;
  }
  #indexTop .concept .conceptDescription ul li .imgBox img {
    width: 100%;
  }
  #indexTop .concept .conceptDescription ul li .txtBox {
    width: 44.93333vw;
    max-width: 337px;
  }
  #indexTop .concept .conceptDescription ul li .txtBox h4 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox h4 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox h4 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox h4 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    font-size: 2.4rem;
    font-size: 1.75695vw;
    padding-bottom: 1.6vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox p {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox a {
    font-size: 2.4rem;
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox a {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox a {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul li .txtBox a.modal1:before {
    right: -3.2vw;
    width: 2.66667vw;
    max-width: 20px;
    height: 2.66667vw;
    max-height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul li .txtBox a.modal1:before {
    right: -24px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptDescription ul::after {
    height: 57.86667vw;
    max-height: 434px;
    width: 91.86667vw;
    max-width: 689px;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptDescription ul::after {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptImgBox {
    margin-right: -8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptImgBox {
    margin-right: -60px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptImgBox .rowItem {
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .concept .conceptImgBox .rowItem {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .concept .conceptImgBox .rowItem:first-of-type {
    width: 32.93333vw;
    max-width: 247px;
    height: 33.06667vw;
    max-height: 248px;
  }
  #indexTop .concept .conceptImgBox .rowItem:last-of-type {
    width: 34.4vw;
    max-width: 258px;
    height: 51.6vw;
    max-height: 387px;
  }
  #indexTop .menu {
    padding-top: 31.33333vw;
    padding-right: 8vw;
    padding-left: 8vw;
    padding-bottom: 16vw;
    margin-top: -31.33333vw;
    width: inherit !important;
    max-width: inherit !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu {
    padding-top: 235px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu {
    margin-top: -235px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu:after {
    background-image: url(/images/index/menu_bt1_sp@2x.jpg);
    height: 48vw;
    max-height: 360px;
  }
  #indexTop .menu h3 {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu h3 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .menu h3 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu h3 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .menu p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox {
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox ul li {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    margin-bottom: 4.26667vw;
    color: #909090;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .menu .menuBox ul li {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox ul li {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox span {
    font-size: 2.4rem;
    font-size: 1.75695vw;
    margin-bottom: 5.33333vw;
    padding-left: 1em;
    text-indent: -1em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .menu .menuBox span {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox span {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList {
    padding-top: 5.33333vw;
    padding-right: 4vw;
    padding-left: 4vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList h4 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    letter-spacing: 0.7px;
    border-bottom: 2px solid #FFF3BA;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList h4 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList h4 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList h4:before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 50px;
    height: 2px;
    background-color: #FFD610;
  }
  #indexTop .menu .menuBox .menuList dl {
    padding-top: 3.2vw;
    padding-right: 4vw;
    padding-left: 4vw;
    padding-bottom: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-top: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl {
    padding-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList dl dt {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList dl dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dt {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList dl dt:last-of-type {
    margin-bottom: 0;
  }
  #indexTop .menu .menuBox .menuList dl dd {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList dl dd {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList dl dd {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList dl dd:last-of-type {
    margin-bottom: 0;
  }
  #indexTop .menu .menuBox .menuList span {
    padding-left: 4vw;
    text-indent: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList span {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList span:first-of-type {
    margin-bottom: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList span:first-of-type {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .menu .menuBox .menuList span:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .menu .menuBox .menuList span:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram {
    margin-top: 21.33333vw;
    margin-bottom: 21.33333vw;
    padding-top: 50.4vw;
    padding-right: 8vw;
    padding-left: 8vw;
    width: inherit !important;
    max-width: inherit !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram {
    padding-top: 378px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram:before {
    background-image: url(/images/index/menu_bt2_sp@2x.jpg);
    right: 8vw;
    width: 61.86667vw;
    max-width: 464px;
    height: 34.93333vw;
    max-height: 262px;
    left: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram:before {
    right: 60px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram h3 {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram h3 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .instagram h3 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram h3 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .instagram p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram #instagramContent {
    width: 100%;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram #instagramContent {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .instagram #instagramContent .instagramContentList {
    padding-left: 1.33333vw;
    padding-right: 1.33333vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram #instagramContent .instagramContentList {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram #instagramContent .instagramContentList {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .instagram #instagramContent .instagramContentList {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq {
    padding-right: 8vw;
    padding-left: 8vw;
    margin-bottom: 21.33333vw;
    width: inherit !important;
    max-width: inherit !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq h3 {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq h3 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .faq h3 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq h3 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq li {
    margin-bottom: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq li:last-child {
    margin-bottom: 0;
  }
  #indexTop .faq li dl {
    border-bottom: 1px solid #373737;
  }
  #indexTop .faq li dl dt {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    padding-top: 1.6vw;
    padding-bottom: 1.6vw;
    padding-right: 8vw;
    padding-left: calc(1em + 1.066vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .faq li dl dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dt {
    padding-top: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dt {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dt {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq li dl dt:hover {
    cursor: pointer;
  }
  #indexTop .faq li dl dt:after {
    content: "";
    background-image: url(/images/index/accordion_plus.svg);
    width: 4.26667vw;
    max-width: 32px;
    height: 4.26667vw;
    max-height: 32px;
    top: 50%;
    right: 1.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dt:after {
    right: 14px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .faq li dl dt.active:after {
    background-image: url(/images/index/accordion_minus.svg);
    transition: all .3s ease-in-out;
  }
  #indexTop .faq li dl dd {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    padding-top: 1.6vw;
    padding-bottom: 1.6vw;
    padding-right: 4vw;
    padding-left: 8vw;
    text-indent: -4vw;
    background-color: #E5E1DD;
    display: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .faq li dl dd {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dd {
    padding-top: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dd {
    padding-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dd {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dd {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #indexTop .faq li dl dd {
    text-indent: -30px;
  }
}

/* ==============================================
店舗情報
============================================== */
#storeTop .profile {
  padding-top: 5.62225vw;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile {
    padding-top: 76.8px;
  }
}

#storeTop .profile .heroWrap {
  margin-bottom: 8.19912vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile .heroWrap {
    margin-bottom: 112px;
  }
}

#storeTop .profile .heroWrap h2 {
  position: absolute;
  text-align: center;
  font-size: 3.6rem;
  font-size: 2.63543vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1366px) {
  #storeTop .profile .heroWrap h2 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile .heroWrap h2 {
    font-size: 4.8vw;
  }
}

#storeTop .profile .reserveDesc {
  font-size: 2rem;
  font-size: 1.46413vw;
  margin-bottom: 4.39239vw;
  text-align: center;
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  margin-left: auto;
  margin-right: auto;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile .reserveDesc {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    padding-right: 30px;
  }
}

#storeTop .profile ul {
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  margin: 0 auto;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul {
    padding-right: 30px;
  }
}

#storeTop .profile ul li {
  margin-bottom: 4.39239vw;
  padding-bottom: 4.39239vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li {
    padding-bottom: 60px;
  }
}

#storeTop .profile ul li:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80%;
  height: 1px;
  background: #707070;
  transform: translate(-50%, 0);
}

#storeTop .profile ul li .descriptionWrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

#storeTop .profile ul li .descriptionWrap h3 {
  font-size: 2.8rem;
  font-size: 2.04978vw;
  display: flex;
  align-items: center;
  margin-bottom: 2.19619vw;
  position: relative;
  flex-basis: 100%;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap h3 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap h3 {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap h3 {
    margin-bottom: 30px;
  }
}

#storeTop .profile ul li .descriptionWrap h3:before {
  content: "";
  width: .5em;
  height: .5em;
  background-color: #FFD610;
  display: inline-block;
  margin-right: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap h3:before {
    margin-right: 10px;
  }
}

#storeTop .profile ul li .descriptionWrap dl {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 2.92826vw;
  flex-basis: 70%;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl {
    margin-bottom: 40px;
  }
}

#storeTop .profile ul li .descriptionWrap dl dt {
  flex-basis: 20%;
  font-size: 1.8rem;
  font-size: 1.31772vw;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dt {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap dl dt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dt {
    margin-bottom: 10px;
  }
}

#storeTop .profile ul li .descriptionWrap dl dt:last-of-type {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dt:last-of-type {
    margin-bottom: 0px;
  }
}

#storeTop .profile ul li .descriptionWrap dl dd {
  flex-basis: 75%;
  font-size: 1.8rem;
  font-size: 1.31772vw;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dd {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap dl dd {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dd {
    margin-bottom: 10px;
  }
}

#storeTop .profile ul li .descriptionWrap dl dd:last-of-type {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dd:last-of-type {
    margin-bottom: 0px;
  }
}

#storeTop .profile ul li .descriptionWrap .linkWrap {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap {
    margin-bottom: 40px;
  }
}

#storeTop .profile ul li .descriptionWrap .linkWrap a {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  padding-top: 0.58565vw;
  padding-bottom: 0.58565vw;
  padding-right: 2.19619vw;
  padding-left: 2.19619vw;
  margin-bottom: 0.73206vw;
  text-align: center;
  background: #45931e;
  color: #f3f3f3;
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    margin-bottom: 10px;
  }
}

#storeTop .profile ul li iframe {
  width: 100%;
  height: 36.60322vw;
  max-height: 500px;
}

@media screen and (max-width: 767px) {
  #storeTop .profile {
    padding-top: 17.704vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile {
    padding-top: 132.78px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile .heroWrap {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile .heroWrap {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile .heroWrap h2 {
    font-size: 4.8rem;
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile .heroWrap h2 {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeTop .profile .heroWrap h2 {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile .reserveDesc {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 8vw;
    text-align: left;
    width: 100%;
    max-width: inherit;
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeTop .profile .reserveDesc {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile .reserveDesc {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul {
    width: 100%;
    max-width: inherit;
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li {
    margin-bottom: 8vw;
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap h3 {
    font-size: 4rem;
    font-size: 2.92826vw;
    display: flex;
    align-items: center;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap h3 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap h3 {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap h3 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap h3:before {
    content: "";
    width: .5em;
    height: .5em;
    background-color: #FFD610;
    display: inline-block;
    margin-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap h3:before {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap dl {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 4vw;
    flex-basis: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap dl dt {
    flex-basis: 20%;
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap dl dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap dl dd {
    flex-basis: 75%;
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap dl dd {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap dl dd {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    padding-right: 4vw;
    padding-left: 4vw;
    margin-bottom: 3.33333vw;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  #storeTop .profile ul li .descriptionWrap .linkWrap a.reserve {
    background: #FFD610;
  }
  #storeTop .profile ul li .descriptionWrap .linkWrap a.line {
    background: #45931e;
    color: #f3f3f3;
  }
  #storeTop .profile ul li .descriptionWrap .linkWrap a.counseling {
    background: #3c3c3c;
    color: #f3f3f3;
  }
  #storeTop .profile ul li iframe {
    height: 47.33333vw;
    max-height: 355px;
  }
}

/* ==============================================
脱毛について
============================================== */
#aboutTop .about {
  padding-top: 5.62225vw;
}

@media screen and (min-width: 1366px) {
  #aboutTop .about {
    padding-top: 76.8px;
  }
}

#aboutTop .about .heroWrap {
  margin-bottom: 8.19912vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .heroWrap {
    margin-bottom: 112px;
  }
}

#aboutTop .about .heroWrap h2 {
  position: absolute;
  text-align: center;
  font-size: 3.6rem;
  font-size: 2.63543vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .heroWrap h2 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .heroWrap h2 {
    font-size: 4.8vw;
  }
}

#aboutTop .about h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #aboutTop .about h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about h3 {
    margin-bottom: 30px;
  }
}

#aboutTop .about .descWrap {
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  padding-bottom: 6.58858vw;
  margin: 0 auto;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap {
    padding-bottom: 90px;
  }
}

#aboutTop .about .descWrap p {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  margin-bottom: 1.46413vw;
  margin-top: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .descWrap p {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap p {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap p {
    margin-top: 20px;
  }
}

#aboutTop .about .descWrap a {
  display: block;
  width: 40%;
  text-align: center;
  margin: 0 auto;
  margin-top: 2.92826vw;
  margin-bottom: 4.39239vw;
  padding-bottom: 0.73206vw;
  padding-top: 0.73206vw;
  padding-right: 0.73206vw;
  padding-left: 0.73206vw;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  background: #FFD610;
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .descWrap a {
    font-size: 2.13333vw;
  }
}

#aboutTop .about .descWrap a:first-of-type {
  position: relative;
}

#aboutTop .about .descWrap a:first-of-type:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.46413vw;
  background: url(/images/about/about_icon.png) center center no-repeat;
  background-size: contain;
  width: 1.7em;
  height: 1.7em;
  transform: translate(0, -50%);
}

@media screen and (min-width: 1366px) {
  #aboutTop .about .descWrap a:first-of-type:after {
    right: 20px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about {
    padding-top: 17.704vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about {
    padding-top: 132.78px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .heroWrap {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .heroWrap {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .heroWrap h2 {
    font-size: 4.8rem;
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .heroWrap h2 {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .about .heroWrap h2 {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about h3 {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about h3 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .about h3 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about h3 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .descWrap {
    width: 100%;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .descWrap p {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 5.33333vw;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .about .descWrap p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap p {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap p {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .descWrap a {
    display: block;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 16vw;
    padding-bottom: 2.66667vw;
    padding-top: 2.66667vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
    font-size: 2.8rem;
    font-size: 2.04978vw;
    background: #FFD610;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .about .descWrap a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .about .descWrap a:first-of-type {
    position: relative;
  }
  #aboutTop .about .descWrap a:first-of-type:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 5.33333vw;
    background: url(/images/about/about_icon.png) center center no-repeat;
    background-size: contain;
    width: 1.7em;
    height: 1.7em;
    transform: translate(0, -50%);
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #aboutTop .about .descWrap a:first-of-type:after {
    right: 40px;
  }
}

/* ==============================================
店舗情報
============================================== */
#jobTop .job {
  padding-top: 5.62225vw;
}

@media screen and (min-width: 1366px) {
  #jobTop .job {
    padding-top: 76.8px;
  }
}

#jobTop .job .heroWrap {
  margin-bottom: 8.19912vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .heroWrap {
    margin-bottom: 112px;
  }
}

#jobTop .job .heroWrap h2 {
  position: absolute;
  text-align: center;
  font-size: 3.6rem;
  font-size: 2.63543vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 1366px) {
  #jobTop .job .heroWrap h2 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .heroWrap h2 {
    font-size: 4.8vw;
  }
}

#jobTop .job .descWrap {
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  padding-bottom: 6.58858vw;
  margin: 0 auto;
  width: 73.20644vw;
  max-width: 1000px;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap {
    padding-bottom: 90px;
  }
}

#jobTop .job .descWrap .descWrapInner {
  margin-bottom: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner {
    margin-bottom: 60px;
  }
}

#jobTop .job .descWrap .descWrapInner:first-of-type img {
  margin-top: 2.19619vw;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner:first-of-type img {
    margin-top: 30px;
  }
}

#jobTop .job .descWrap .descWrapInner.flex {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

#jobTop .job .descWrap .descWrapInner.flex p {
  width: 55%;
}

#jobTop .job .descWrap .descWrapInner.flex img {
  width: 40%;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}

#jobTop .job .descWrap .descWrapInner h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  font-weight: bold;
  text-align: center;
  position: relative;
  margin-bottom: 2.19619vw;
  padding-bottom: 0.73206vw;
  width: 100%;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    padding-bottom: 10px;
  }
}

#jobTop .job .descWrap .descWrapInner h3:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 3px;
  width: 4.39239vw;
  max-width: 60px;
  background: #FFD610;
}

#jobTop .job .descWrap .descWrapInner p {
  text-align: inherit;
  font-size: 1.8rem;
  font-size: 1.31772vw;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner p {
    font-size: 2.4vw;
  }
}

#jobTop .job .descWrap .descWrapInner a {
  display: block;
  text-align: center;
  width: 40%;
  margin: 0 auto;
  font-size: 1.8rem;
  font-size: 1.31772vw;
  padding-top: 0.73206vw;
  padding-bottom: 0.73206vw;
  margin-bottom: 2.19619vw;
  margin-top: 2.19619vw;
  background: #FFD610;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    margin-top: 30px;
  }
}

#jobTop .job .descWrap .descWelfareBenefits {
  position: relative;
  margin-bottom: 4.39239vw;
  padding-bottom: 2.19619vw;
  padding-top: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits {
    padding-top: 30px;
  }
}

#jobTop .job .descWrap .descWelfareBenefits:before {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100%;
  background: #fff;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
}

#jobTop .job .descWrap .descWelfareBenefits h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  margin-bottom: 1.0981vw;
  padding-bottom: 0.73206vw;
  font-weight: bold;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    padding-bottom: 10px;
  }
}

#jobTop .job .descWrap .descWelfareBenefits h3:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 3px;
  width: 4.39239vw;
  max-width: 60px;
  background: #FFD610;
}

#jobTop .job .descWrap .descWelfareBenefits ul {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

#jobTop .job .descWrap .descWelfareBenefits ul li {
  width: calc(100% / 4 - 10px);
}

#jobTop .job .descWrap .descWelfareBenefits ul li .textBox {
  padding-bottom: 0.73206vw;
  padding-top: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox {
    padding-top: 10px;
  }
}

#jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
    margin-bottom: 10px;
  }
}

#jobTop .job .descWrap .descWelfareBenefits ul li .textBox p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job {
    padding-top: 17.704vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job {
    padding-top: 132.78px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .heroWrap {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .heroWrap {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .heroWrap h2 {
    font-size: 4.8rem;
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .heroWrap h2 {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #jobTop .job .heroWrap h2 {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap {
    width: 100%;
    padding-left: 8vw;
    padding-right: 8vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner:first-of-type img {
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner:first-of-type img {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner.flex p {
    width: 100%;
  }
  #jobTop .job .descWrap .descWrapInner.flex img {
    width: 100%;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner.flex img {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 8vw;
    padding-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner h3 {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner h3:after {
    width: 16vw;
    max-width: 120px;
  }
  #jobTop .job .descWrap .descWrapInner p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner a {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
    margin-bottom: 8vw;
    margin-top: 8vw;
    width: 80%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #jobTop .job .descWrap .descWrapInner a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWrapInner a {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits {
    margin-bottom: 16vw;
    padding-bottom: 8vw;
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 4vw;
    padding-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits h3 {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits h3:after {
    width: 16vw;
    max-width: 120px;
  }
  #jobTop .job .descWrap .descWelfareBenefits ul li {
    width: 100%;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li img {
    width: 50%;
    margin: 0 auto;
  }
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox {
    padding-bottom: 2.66667vw;
    padding-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
    font-size: 3rem;
    font-size: 2.19619vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #jobTop .job .descWrap .descWelfareBenefits ul li .textBox p {
    font-size: 3.73333vw;
  }
}

/* ==============================================
脱毛器レンタル
============================================== */
#rentalTop .rental {
  padding-top: 5.62225vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental {
    padding-top: 76.8px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental {
    padding-top: 17.704vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental {
    padding-top: 132.78px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .heroWrap {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .heroWrap {
    margin-bottom: 150px;
  }
}

#rentalTop .rental .conBtn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

#rentalTop .rental .conBtn a {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  display: block;
  line-height: 1;
  padding: 1em 2.5em;
  background: #FFD610;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .conBtn a {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .conBtn a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .conBtn {
    right: 5.33333vw;
    bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .conBtn {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .conBtn {
    bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .conBtn a {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .conBtn a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .conBtn a {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .heroWrap {
  margin-bottom: 8.19912vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .heroWrap {
    margin-bottom: 112px;
  }
}

#rentalTop .rental .descWrap {
  padding-left: 2.19619vw;
  padding-right: 2.19619vw;
  padding-bottom: 6.58858vw;
  margin: 0 auto;
  width: 77.59883vw;
  max-width: 1060px;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap {
    width: 100%;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap {
    padding-bottom: 180px;
  }
}

#rentalTop .rental .descWrap .ttl {
  text-align: center;
  margin-bottom: 8.78477vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .ttl {
    margin-bottom: 120px;
  }
}

#rentalTop .rental .descWrap .ttl h2 {
  display: inline;
  font-size: 2.8rem;
  font-size: 2.04978vw;
  background: linear-gradient(transparent 70%, #FFD610 20%);
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .ttl h2 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .ttl h2 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .ttl {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .ttl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .ttl h2 {
    font-size: 3.6rem;
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .ttl h2 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .ttl h2 {
    font-size: 4.8vw;
  }
}

#rentalTop .rental .descWrap .listArea h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  margin-bottom: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea h3 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea h3 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea h3 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea h3 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea h3 {
    margin-bottom: 60px;
  }
}

#rentalTop .rental .descWrap .listArea ol {
  margin-bottom: 5.85652vw;
  counter-reset: count 0;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol {
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol {
    margin-bottom: 90px;
  }
}

#rentalTop .rental .descWrap .listArea ol li {
  padding-left: 7.32064vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li {
    padding-left: 100px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li {
    padding-left: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li {
    padding-left: 70px;
  }
}

#rentalTop .rental .descWrap .listArea ol li:before {
  content: "0" counter(count);
  counter-increment: count 1;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 6rem;
  font-size: 4.39239vw;
  line-height: 1;
  padding-bottom: .25em;
  border-bottom: 3px solid #FFD610;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li:before {
    font-size: 6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li:before {
    font-size: 8vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li:before {
    font-size: 4.8rem;
    font-size: 3.51391vw;
    border-bottom: 2px solid #FFD610;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li:before {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li:before {
    font-size: 6.4vw;
  }
}

#rentalTop .rental .descWrap .listArea ol li:not(:last-child) {
  margin-bottom: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li:not(:last-child) {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li:not(:last-child) {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li:not(:last-child) {
    margin-bottom: 60px;
  }
}

#rentalTop .rental .descWrap .listArea ol li h4 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li h4 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li h4 {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li h4 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li h4 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li h4 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li h4 {
    margin-bottom: 20px;
  }
}

#rentalTop .rental .descWrap .listArea ol li p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li p {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .listArea ol li p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .listArea ol li p {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec h3 {
  font-size: 2.4rem;
  font-size: 1.75695vw;
  margin-bottom: 4.39239vw;
  text-align: center;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec h3 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec h3 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec h3 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec h3 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec h3 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec h3 {
    margin-bottom: 60px;
  }
}

#rentalTop .rental .descWrap .machineSpec .tabArea {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}

#rentalTop .rental .descWrap .machineSpec .tabArea p {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  background: #E8E8E8;
  border-bottom: 1px solid #E0E0E0;
  width: 50%;
  text-align: center;
  padding: .625em 0;
  opacity: .5;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .tabArea p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .tabArea p {
    font-size: 2.13333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .tabArea p.active {
  font-weight: bold;
  border-bottom: none;
  background: none;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .tabArea p {
    font-size: 2.4rem;
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .tabArea p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .tabArea p {
    font-size: 3.2vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList {
  padding-top: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList {
    padding-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList {
    padding-top: 40px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon {
  display: none;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon.active {
  display: flex;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img {
  position: sticky;
  left: 0;
  top: 5.62225vw;
  width: 50%;
  padding-right: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img {
    top: 76.8px;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img {
    position: relative;
    top: 0;
    width: 100%;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img {
    padding-right: 0px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide {
  margin-top: 0.73206vw;
  width: 100%;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide {
    margin-top: 10px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .slick-track {
  transform: none !important;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .slick-slide img {
  border: 1px solid #EFEFEF;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .slick-slide.slick-current img {
  border: 1px solid #FFD610;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .inner {
  padding-left: 0.73206vw;
  padding-right: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .inner {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .inner {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide {
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .inner {
    padding-left: 1.33333vw;
    padding-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .inner {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .img .subSlide .inner {
    padding-right: 10px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt {
    width: 100%;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt {
    margin-top: 40px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
  font-size: 1.8rem;
  font-size: 1.31772vw;
  border-bottom: 1px solid #FFF3BA;
  line-height: 2;
  position: relative;
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
    margin-bottom: 30px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4:before {
  content: "";
  width: 3.66032vw;
  max-width: 50px;
  height: 1px;
  background: #FFD610;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt h4:before {
    width: 13.33333vw;
    max-width: 100px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4.39239vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc {
    margin-bottom: 60px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dt {
  width: 32%;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  margin-bottom: 1em;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dt {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dt {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd {
  width: 68%;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  margin-bottom: 1em;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li {
  display: flex;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .descTtl {
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .descTtl {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .descTtl {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .descTtl {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .descTtl {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .descTtl {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .desc {
  flex: 1;
  font-size: 1.6rem;
  font-size: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .desc {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .desc {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .desc {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .desc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .mainDesc dd ul li .desc {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion {
  margin-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion {
    margin-bottom: 80px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl {
  border-bottom: 1px solid #373737;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  letter-spacing: 0.5px;
  padding: 1.5em 2em .6em 0;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt:hover {
  cursor: pointer;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt:after {
  content: "";
  display: block;
  background-image: url(/images/index/accordion_plus.svg);
  width: 1.1713vw;
  max-width: 16px;
  height: 1.1713vw;
  max-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 0.58565vw;
  transform: translate(0, -50%);
  transition: all .3s ease-in-out;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt:after {
    right: 8px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt:after {
    width: 4.26667vw;
    max-width: 32px;
    height: 4.26667vw;
    max-height: 32px;
    top: 50%;
    right: 1.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt:after {
    right: 14px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dt.active:after {
  background-image: url(/images/index/accordion_minus.svg);
  transition: all .3s ease-in-out;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dd {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  letter-spacing: 0.5px;
  padding: 1.5em 1.6em .6em 1.6em;
  background-color: #E5E1DD;
  display: none;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dd {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dd {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dd {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li dl dd {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .accordion li:nth-child(1) dt {
  padding-top: 0 !important;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea {
  border-left: 1px solid #707070;
  border-top: 1px solid #707070;
  margin-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea {
    margin-bottom: 80px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea th {
  width: 34%;
  text-align: center;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  font-weight: 500;
  padding: 1em 0;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea th {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea th {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea th {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea th {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea th {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea td {
  width: 66%;
  text-align: center;
  font-size: 1.6rem;
  font-size: 1.1713vw;
  font-weight: 500;
  padding: 1em 0;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea td {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea td {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea td {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea td {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .priceArea td {
    font-size: 3.73333vw;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li:not(:last-child) {
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li:not(:last-child) {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li:not(:last-child) {
    margin-bottom: 40px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
  font-size: 1.6rem;
  font-size: 1.1713vw;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
    font-size: 2.8rem;
    font-size: 2.04978vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectTtl {
    margin-bottom: 20px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectImg {
  display: flex;
  justify-content: space-between;
  position: relative;
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectImg:before {
  content: "";
  width: 0.95168vw;
  max-width: 13px;
  height: 0.95168vw;
  max-height: 13px;
  border-top: 1px solid #FFD610;
  border-right: 1px solid #FFD610;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-75%, -50%) rotate(45deg);
}

@media screen and (max-width: 767px) {
  #rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectImg:before {
    width: 3.46667vw;
    max-width: 26px;
    height: 3.46667vw;
    max-height: 26px;
  }
}

#rentalTop .rental .descWrap .machineSpec .specList .machineSpecCon .txt .effectArea li .effectImg img {
  width: 45%;
}
