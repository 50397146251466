@charset "utf-8";

/* ==============================================
脱毛器レンタル
============================================== */

#rentalTop{
	.rental{
		@include props(pt,76.8);
		position: relative;

		@include mq(md){
			@include props(pt,132.78,750);
			.heroWrap{
				@include props(mb,150,750);
			}
		}

		.conBtn{
			position: fixed;
			right: 20px;
			bottom: 20px;
			z-index: 10;

			a{
				@include fz_vw(16);
				display: block;
				line-height: 1;
				padding: 1em 2.5em;
				background: #FFD610;
			}

			@include mq(md){
				@include props(r,40,750);
				@include props(b,40,750);

				a{
					@include fz_vw(28);
				}
			}
		}

		.heroWrap{
			@include props(mb,112);
		}

		.descWrap{
			@include props(pl,30);
			@include props(pr,30);
			@include props(pb,90);
			margin: 0 auto;
			@include props(w,1060);

			@include mq(md){
				width: 100%;
				@include props(pl,40,750);
				@include props(pr,40,750);
				@include props(pb,180,750);
			}

			.ttl{
				text-align: center;
				@include props(mb,120);
				h2{
					display: inline;
					@include fz_vw(28);
					background: linear-gradient(transparent 70%,#FFD610 20%);
				}

				@include mq(md){
					@include props(mb,80,750);

					h2{
						@include fz_vw(36);
					}
				}
			}

			.listArea{
				h3{
					@include fz_vw(24);
					@include props(mb,60);

					@include mq(md){
						@include fz_vw(32);
						@include props(mb,60,750);
					}
				}

				ol{
					@include props(mb,80);
					counter-reset: count 0;

					@include mq(md){
						@include props(mb,90,750);
					}
					li{
						@include props(pl,100);
						position: relative;

						@include mq(md){
							@include props(pl,70,750);
						}

						&:before{
							content: "0"counter(count);
							counter-increment: count 1;
							position: absolute;
							top: 0;
							left: 0;
							@include fz_vw(60);
							line-height: 1;
							padding-bottom: .25em;
							border-bottom: 3px solid #FFD610;

							@include mq(md){
								@include fz_vw(48);
								border-bottom: 2px solid #FFD610;
							}
						}
						&:not(:last-child){
							@include props(mb,60);

							@include mq(md){
								@include props(mb,60,750);
							}
						}
						h4{
							@include fz_vw(18);
							@include props(mb,20);

							@include mq(md){
								@include fz_vw(32);
								@include props(mb,20,750);
							}
						}

						p{
							@include fz_vw(16);

							@include mq(md){
								@include fz_vw(28);
							}
						}
					}
				}
			}

			.machineSpec{
				h3{
					@include fz_vw(24);
					@include props(mb,60);
					text-align: center;

					@include mq(md){
						@include fz_vw(32);
						@include props(mb,60,750);
					}
				}

				.tabArea{
					display: flex;
					justify-content: center;
					align-items: center;
					border-top: 1px solid #E0E0E0;
					border-left: 1px solid #E0E0E0;
					border-right: 1px solid #E0E0E0;

					p{
						@include fz_vw(16);
						background: #E8E8E8;
						border-bottom: 1px solid #E0E0E0;
						width: 50%;
						text-align: center;
						padding: .625em 0;
						opacity: .5;
						&.active{
							font-weight: bold;
							border-bottom: none;
							background: none;
							opacity: 1;
						}

						@include mq(md){
							@include fz_vw(24);
						}
					}
				}

				.specList{
					@include props(pt,40);

					@include mq(md){
						@include props(pt,40,750);
					}

					.machineSpecCon{
						display: none;
						align-items: flex-start;
						flex-wrap: wrap;
						position: relative;
						&.active{
							display: flex;
						}
						.img{
							position: sticky;
							left: 0;
							@include props(t,76.8);
							width: 50%;
							@include props(pr,40);

							@include mq(md){
								position: relative;
								top: 0;
								width: 100%;
								@include props(pr,0);
							}

							.subSlide{
								@include props(mt,10);
								width: 100%;

								.slick-track{
									transform:none !important;
								}
								.slick-slide{
										img{
											border: 1px solid #EFEFEF;
										}

									&.slick-current{
											img{
												border: 1px solid #FFD610;
											}
									}
								}
								.inner{
									@include props(pl,10);
									@include props(pr,10);
								}

								@include mq(md){
									@include props(mt,20,750);
									.inner{
										@include props(pl,10,750);
										@include props(pr,10,750);
									}
								}
							}
						}

						.txt{
							width: 50%;

							@include mq(md){
								width: 100%;
								@include props(mt,40,750);
							}

							h4{
								@include fz_vw(18);
								border-bottom: 1px solid #FFF3BA;
								line-height: 2;
								position: relative;
								@include props(mb,30);
								&:before{
									content: "";
									@include props(w,50);
									height: 1px;
									background: #FFD610;
									position: absolute;
									bottom: 0;
									left: 0;
								}

								@include mq(md){
									@include fz_vw(32);
									@include props(mb,40,750);
									&:before{
										@include props(w,100,750);
									}
								}
							}

							.mainDesc{
								display: flex;
								flex-wrap: wrap;
								@include props(mb,60);

								@include mq(md){
									@include props(mb,60,750);
								}

								dt{
									width: 32%;
									@include fz_vw(16);
									margin-bottom: 1em;

									@include mq(md){
										@include fz_vw(28);
									}
								}
								dd{
									width: 68%;
									@include fz_vw(16);
									margin-bottom: 1em;

									@include mq(md){
										@include fz_vw(28);
									}

									ul{
										li{
											display: flex;
											.descTtl{
												@include fz_vw(16);
												@include mq(md){
													@include fz_vw(28);
												}
											}

											.desc{
												flex: 1;
												@include fz_vw(16);
												@include mq(md){
													@include fz_vw(28);
												}
											}
										}
									}
								}
							}

							.accordion{
								@include props(mb,80);

								@include mq(md){
									@include props(mb,80,750);
								}
								li{
									dl{
										border-bottom: 1px solid $basicColor;
										dt{
											@include fz_vw(16);
											letter-spacing: 0.5px;
											padding: 1.5em 2em .6em 0;
											position: relative;
	
											@include mq(md){
												@include fz_vw(28);
											}
						
											&:hover{
												cursor: pointer;
											}
						
											&:after{
												content: "";
												display: block;
												background-image: url(/images/index/accordion_plus.svg);
												@include props(w,16);
												@include props(h,16);
												background-size: contain;
												background-position: center;
												background-repeat: no-repeat;
												position: absolute;
												top: 50%;
												@include props(r,8);
												transform: translate(0,-50%);
												transition: all .3s ease-in-out;

												@include mq(md){
													@include props(w,32,750);
													@include props(h,32,750);
													top: 50%;
													@include props(r,14,750);
												}
											}
						
											&.active{
												&:after{
													background-image: url(/images/index/accordion_minus.svg);
													transition: all .3s ease-in-out;
												}	
											}
										}
							
										dd{
											@include fz_vw(16);
											letter-spacing: 0.5px;
											padding: 1.5em 1.6em .6em 1.6em;
											background-color: $accentbg;
											display: none;
	
											@include mq(md){
												@include fz_vw(28);
											}
										}
									}
									&:nth-child(1){
										dt{
											padding-top: 0 !important;
										}
									}
								}
								
							}

							.priceArea{
								border-left: 1px solid #707070;
								border-top: 1px solid #707070;
								@include props(mb,80);

								@include mq(md){
									@include props(mb,80,750);
								}
								th{
									width: 34%;
									text-align: center;
									@include fz_vw(16);
									font-weight: 500;
									padding: 1em 0;
									border-bottom: 1px solid #707070;
									border-right: 1px solid #707070;

									@include mq(md){
										@include fz_vw(28);
									}
								}
								td{
									width: 66%;
									text-align: center;
									@include fz_vw(16);
									font-weight: 500;
									padding: 1em 0;
									border-bottom: 1px solid #707070;
									border-right: 1px solid #707070;

									@include mq(md){
										@include fz_vw(28);
									}
								}
							}

							.effectArea{
								li{
									&:not(:last-child){
										@include props(mb,40);

										@include mq(md){
											@include props(mb,40,750);
										}
									}
									.effectTtl{
										@include fz_vw(16);
										@include props(mb,20);

										@include mq(md){
											@include fz_vw(28);
											@include props(mb,20,750);
										}
									}

									.effectImg{
										display: flex;
										justify-content: space-between;
										position: relative;

										&:before{
											content: "";
											@include props(w,13);
											@include props(h,13);
											border-top: 1px solid #FFD610;
											border-right: 1px solid #FFD610;
											position: absolute;
											top: 50%;
											left: 50%;
											transform: translate(-75%,-50%)rotate(45deg);

											@include mq(md){
												@include props(w,26,750);
												@include props(h,26,750);
											}
										}

										img{
											width: 45%;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}