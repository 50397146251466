@charset "utf-8";

/* ==============================================
トップページ
============================================== */

#indexTop{
	@include props(pt,76.8);

	.fv{
		width: 100vw;

		.pcOnly{
			width: 100%;
		}
	}

	.concept{
		@include props(pt,112);
		@include props(pl,30);
		@include props(pr,30);
		margin: 0 auto;
		@include props(w,1000);

		.conceptRead{
			margin: 0 auto;
			@include props(mb, 56);
			h3{
				@include fz_vw(24);
				letter-spacing: 0.7px;
				@include props(mb, 40);
			}

			p{
				@include fz_vw(18);
				letter-spacing: 0.7px;
			}
		}

		.conceptDescription{
			ul{
				li{
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					@include props(mb, 64);

					.imgBox{
						@include props(w, 478);
						img{
							width: 100%;
						}
					}

					.txtBox{
						@include props(w, 400);

						h4{
							@include fz_vw(24);
							letter-spacing: 0.7px;
							@include props(mb, 24);
						}

						p{
							@include fz_vw(18);
							letter-spacing: 0.7px;
							@include props(pb,16);
							border-bottom:1px solid $borderColor;
						}

						a{
							@include fz_vw(14);
							position: relative;
							color: #909090;

							&.modal1:before{
								content: "";
								position: absolute;
								top: 50%;
								right: -14px;
								background-image: url(/images/index/modal_btn.svg);
								width: 12px;
								height: 12px;
								background-size: contain;
								background-repeat: no-repeat;
								transform: translate(0,-48%);
							}
						}
					}
				}

				&::after{
					content: "";
					display: block;
					background-image: url(/images/index/consept_bt1_pc@2x.jpg);
					@include props(h,472);
					@include props(w,750);
					background-size: contain;
					background-repeat: no-repeat;
					@include props(mb,30);
					@include props(mr,-30);
					margin-left: auto;
				}
			}
		}

		.conceptImgBox{
			display: flex;
			justify-content: flex-end;
			@include props(mb, 30);
			@include props(mr, -30);
			position: relative;
			z-index: 1;

			.rowItem{
				@include props(ml,30);
			}
		}
	}

	.menu{
		@include props(pt,132);
		@include props(pb,64);
		@include props(pl,30);
		@include props(pr,30);
		position: relative;
		margin: -132px auto 0;
		@include props(w,1000);

		&:before{
			content:"";
			position: absolute;
			top: 0;
			left: 50%;
			width: 100vw;
			height: 100%;
			background: $accentbg;
			transform: translate(-50%,0);
			z-index: -1;
		}

		&:after{
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			background-image: url(/images/index/menu_bt1_pc@2x.jpg);
			width: 100%;
			@include props(h,392);
			background-repeat: no-repeat;
			background-size: contain;
			transform: translate(0,90%);
			display: block;
		}

		h3{
			@include fz_vw(24);
			letter-spacing: 0.7px;
			@include props(mb,40);
		}

		p{
			@include fz_vw(18);
			letter-spacing: 0.7px;
		}

		.menuBox{
			margin-top: 20px;
			
			ul{
				display: flex;
				li{
					flex-basis: 50%;
					@include fz_vw(16);
					letter-spacing: 0.7px;
					text-align: center;
					@include props(pt,10);
					@include props(pb,10);
					@include props(mb,20);
					color: $borderColor;

					&:hover{
						cursor: pointer;
					}

					&.active{
						border-bottom: 2px solid $mainColor;
						color: $basicColor;
					}
				}
			}

			span{
				@include fz_vw(14);
				letter-spacing: 0.7px;
				line-height: 1.5;
				display: block;
				@include props(mb,20);
			}

			.menuList{
				background-image: url(/images/index/menu_bg_pc.jpg);
				background-repeat: no-repeat;
				background-size: cover;
				@include props(pt,40);
				@include props(pb,24);
				@include props(pr,30);
				@include props(pl,30);
				display: none;

				&.active{
					display: block;
				}

				h4{
					@include fz_vw(20);
					letter-spacing: 0.7px;
					border-bottom: 2px solid #FFF3BA;
					position: relative;

					&:before{
						content: "";
						position: absolute;
						bottom: -2px;
						left: 0;
						width: 50px;
						height: 2px;
						background-color: $mainColor;
					}
				}

				dl{
					@include props(pt,20);
					@include props(pb,20);
					@include props(pr,30);
					@include props(pl,30);
					display: flex;
					flex-wrap: wrap;

					dt{
						flex-basis: 60%;
						@include fz_vw(16);
						@include props(mb,10);

						&:last-of-type{
							margin-bottom: 0;
						}
					}

					dd{
						flex-basis: 40%;
						text-align: end;
						@include fz_vw(16);
						@include props(mb,10);

						&:last-of-type{
							margin-bottom: 0;
						}
					}
				}

				span{
					@include props(pl,30);
					&:first-of-type{
						@include props(mb,5);
					}
				}
			}
		}
	}

	.instagram{
		@include props(mt,80);
		@include props(mb,80);
		margin-left: auto;
		margin-right: auto;
		@include props(pt,394);
		@include props(pb,0);
		@include props(pr,30);
		@include props(pl,30);
		position: relative;
		@include props(w,1000);
		

		&:before{
			content: "";
			display: block;
			background-image: url(/images/index/menu_bt2_pc@2x.jpg);
			position: absolute;
			@include props(t,90);
			@include props(l,260);
			@include props(h,270);
			@include props(w,505);
			background-repeat: no-repeat;
			background-size: contain;
		}

		h3{
			@include fz_vw(24);
			letter-spacing: 0.7px;
			margin-bottom: 40px;
		}

		p{
			@include fz_vw(18);
			letter-spacing: 0.7px;
			color: $mainColor;
		}

		#instagramContent{
			width: 100%;
    		margin-top: 12px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.instagramContentList{
				width: calc(100% / 3);
				@include props(pl,10);
				@include props(pr,10);
				@include props(mb,20);

				img{
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.faq{
		@include props(pr,30);
		@include props(pl,30);
		margin: 0 auto 80px;
		@include props(w,1000);

		h3{
			@include fz_vw(24);
			letter-spacing: 0.7px;
			@include props(mb,40);
		}

		li{
			@include props(mb,10);
			&:last-child{
				margin-bottom: 0;
			}
			dl{
				border-bottom: 1px solid $basicColor;
				dt{
					@include fz_vw(18);
					letter-spacing: 0.5px;
					padding: 6px 30px 6px calc(1em + 6px);
					text-indent: -1em;
					position: relative;

					&:hover{
						cursor: pointer;
					}

					&:after{
						content: "";
						display: block;
						background-image: url(/images/index/accordion_plus.svg);
						@include props(w,16);
						@include props(h,16);
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						position: absolute;
						top: 50%;
						@include props(r,8);
						transform: translate(0,-50%);
						transition: all .3s ease-in-out;
					}

					&.active{
						&:after{
							background-image: url(/images/index/accordion_minus.svg);
							transition: all .3s ease-in-out;
						}	
					}
				}
	
				dd{
					@include fz_vw(18);
					letter-spacing: 0.5px;
					padding: 6px 15px 6px 30px;
					text-indent: -15px;
					background-color: $accentbg;
					display: none;
				}
			}
		}
	}
}

@include mq(md){

	#indexTop{
		width: 100%;
		@include props(pt,132.78,750);
		box-shadow: none;
			
	
		.fv{
			display: flex;
			justify-content: space-between;
			@include props(pl,60,750);
	
			.txtContent{
				writing-mode: vertical-rl;
				display: flex;
				@include props(pt,40,750);
				@include props(pr,30,750);
	
				h2{
					@include fz_vw(24);
					@include props(mb,174,750);
					letter-spacing: 1px;
					line-height: 1.5;
				}
	
				span{
					@include fz_vw(24);
					line-height: 1.5;
					position: relative;
	
					&:before{
						content: "";
						position:absolute;
						left: 0;
						top: 0;
						@include props(h,343,750);
						width: 1px;
						background: #707070;
					}
				}
			}
		}
	
		.concept{
			@include props(pt,150,750);
			@include props(pl,60,750);
			@include props(pr,60,750);
			width: inherit !important;
			max-width: inherit !important;
	
			.conceptRead{
				@include props(mb,80,750);

				h3{
					@include fz_vw(36);
					@include props(mb,72,750);
				}
	
				p{
					@include fz_vw(28);
				}
			}
	
			.conceptDescription{
				ul{
					li{
						@include props(mb,80,750);
	
						.imgBox{
							@include props(w,253,750);
							img{
								width: 100%;
							}
						}
	
						.txtBox{
							@include props(w,337,750);
	
							h4{
								@include fz_vw(32);
								@include props(mb,40,750);
							}
	
							p{
								@include fz_vw(24);
								@include props(pb,12,750);
								@include props(mb,12,750);
							}
	
							a{
								@include fz_vw(24);
	
								&.modal1:before{
									@include props(r,-24,750);
									@include props(w,20,750);
									@include props(h,20,750);
								}
							}
						}
					}
	
					&::after{
						@include props(h,434,750);
						@include props(w,689,750);
						@include props(mb,30,750);
					}
				}
			}
	
			.conceptImgBox{
				@include props(mr,-60,750);
	
				.rowItem{
					@include props(ml,30,750);

					&:first-of-type{
						@include props(w,247,750);
						@include props(h,248,750);
					}

					&:last-of-type{
						@include props(w,258,750);
						@include props(h,387,750);
					}
				}
			}
		}
	
		.menu{
			@include props(pt,235,750);
			@include props(pr,60,750);
			@include props(pl,60,750);
			@include props(pb,120,750);
			@include props(mt,-235,750);
			width: inherit !important;
			max-width: inherit !important;
	
			&:after{
				background-image: url(/images/index/menu_bt1_sp@2x.jpg);
				@include props(h,360,750);
			}
	
			h3{
				@include fz_vw(36);
				@include props(mb,80,750);
			}
	
			p{
				@include fz_vw(28);
			}
	
			.menuBox{
				@include props(mt,40,750);
				
				ul{
					li{
						@include fz_vw(28);
						@include props(pt,16,750);
						@include props(pb,16,750);
						@include props(mb,32,750);
						color: $borderColor;
	
						&:hover{
						}
	
						&.active{
						}
					}
				}
	
				span{
					@include fz_vw(24);
					@include props(mb,40,750);
					padding-left: 1em;
					text-indent: -1em;
				}
	
				.menuList{
					@include props(pt,40,750);
					@include props(pr,30,750);
					@include props(pl,30,750);
					@include props(pb,40,750);
	
					&.active{
					}
	
					h4{
						@include fz_vw(32);
						letter-spacing: 0.7px;
						border-bottom: 2px solid #FFF3BA;
						position: relative;
	
						&:before{
							content: "";
							position: absolute;
							bottom: -2px;
							left: 0;
							width: 50px;
							height: 2px;
							background-color: $mainColor;
						}
					}
	
					dl{
						@include props(pt,24,750);
						@include props(pr,30,750);
						@include props(pl,30,750);
						@include props(pb,24,750);
	
						dt{
							@include fz_vw(28);
							@include props(mb,12,750);
	
							&:last-of-type{
								margin-bottom: 0;
							}
						}
	
						dd{
							@include fz_vw(28);
							@include props(mb,12,750);
	
							&:last-of-type{
								margin-bottom: 0;
							}
						}
					}
	
					span{
						@include props(pl,30,750);
						text-indent: inherit;
						&:first-of-type{
							@include props(mb,8,750);
						}

						&:last-of-type{
							@include props(mb,0,750);
						}
					}
				}
			}
		}
	
		.instagram{
			@include props(mt,160,750);
			@include props(mb,160,750);
			@include props(pt,378,750);
			@include props(pr,60,750);
			@include props(pl,60,750);
			width: inherit !important;
			max-width: inherit !important;
	
			&:before{
				background-image: url(/images/index/menu_bt2_sp@2x.jpg);
				@include props(r,60,750);
				@include props(w,464,750);
				@include props(h,262,750);
				left: inherit;
			}
	
			h3{
				@include fz_vw(36);
				@include props(mb,80,750);
			}
	
			p{
				@include fz_vw(28);
			}
	
			#instagramContent{
				width: 100%;
				@include props(mb,20,750);
	
				.instagramContentList{
					@include props(pl,10,750);
					@include props(pr,10,750);
					@include props(mb,20,750);
				}
			}
		}
	
		.faq{
			@include props(pr,60,750);
			@include props(pl,60,750);
			@include props(mb,160,750);
			width: inherit !important;
			max-width: inherit !important;
	
			h3{
				@include fz_vw(36);
				@include props(mb,80,750);
			}
	
			li{
				@include props(mb,24,750);
				&:last-child{
					margin-bottom: 0;
				}
				dl{
					border-bottom: 1px solid $basicColor;
					dt{
						@include fz_vw(28);
						@include props(pt,12,750);
						@include props(pb,12,750);
						@include props(pr,60,750);
						padding-left: calc(1em + 1.066vw);
	
						&:hover{
							cursor: pointer;
						}
	
						&:after{
							content: "";
							background-image: url(/images/index/accordion_plus.svg);
							@include props(w,32,750);
							@include props(h,32,750);
							top: 50%;
							@include props(r,14,750);
						}
	
						&.active{
							&:after{
								background-image: url(/images/index/accordion_minus.svg);
								transition: all .3s ease-in-out;
							}	
						}
					}
		
					dd{
						@include fz_vw(28);
						@include props(pt,12,750);
						@include props(pb,12,750);
						@include props(pr,30,750);
						@include props(pl,60,750);
						@include props(ti,-30,750);
						background-color: $accentbg;
						display: none;
					}
				}
			}
		}
	
	}

}
